<footer id="footer">
  <div class="page-footer-section fg-white" style=" background-color: #000;">
    <div class="container">
        <hr class="bg-white">
        <div class="row mb-5 py-3">
            <div class="col-md-5 col-lg-5 py-3">
                <b class="mb-3">Contacto</b>
                <ul class="menu-link">
                  <li><b class="text-white">Atención al cliente:</b></li>
                  <li><a class="text-white">3104351937 solo WhatsApp</a></li>
                  <li><b class="text-white">Correo:</b></li>
                  <li><a class="text-white">contactodescuentosmedicos@gmail.com</a></li>
                  <li><b class="text-white">Dirección:</b></li>
                  <li><a class="text-white">Pasto - Nariño - Colombia 520002</a></li>
                  <li><b class="text-white">Data Universal Numering System (NUMERO REGISTRO INTERNACIONAL):</b></li>
                  <li><a class="text-white"> DUNS - 885246680 </a></li>
                </ul>
            </div>
            <div class="col-md-3 col-lg-3 py-3">
                <b class="mb-3">Paginas</b>
                <ul class="menu-link">
                    <li><a target="_blank" href="http://citas.prevenirexpress.com/#/login" class="text-white">Descuentos médicos</a></li>
                    <li><a target="_blank" href="https://www.facebook.com/prevenircentrooptico/" class="text-white">Prevenir óptica</a></li>
                    <li><a target="_blank" href="http://www.prevenirexpress.com/inicio" class="text-white">Prevision funeraria</a></li>
                    <li><a target="_blank" href="http://www.prevenirexpress.com/" class="text-white">Unidades de negocio</a></li>
                </ul>
            </div>
            <div class="col-md-4 col-lg-4 py-3">
                <b class="mb-3">Redes sociales</b>
                <p class="text-white">Siguenos en nuestras redes para más información.</p>
                <!-- Social Media Button -->
                <div class="mt-3">
                  <a target="_blank" href="https://www.facebook.com/prevenirdescuentosmedicos" class="btn btn-fab btn-info fg-white"><span class="mai-logo-facebook"></span></a>
                  <a target="_blank" href="https://www.instagram.com/prevenir_descuentos_medicos/" class="btn btn-fab btn-primary fg-white"><span class="mai-logo-instagram"></span></a>
                  <a target="_blank" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20saber%20más%20de%20Prevenir%20Express!" class="btn btn-fab btn-success fg-white"><span class="mai-logo-whatsapp"></span></a>
                  <a target="_blank" href="https://www.youtube.com/channel/UCm4uidXQl8CoYR0GX6JXenw" class="btn btn-fab btn-danger fg-white"><span class="mai-logo-youtube"></span></a>
              </div>
            </div>
        </div>
    <hr class="bg-white">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 py-2">
                <!-- Please don't remove or modify the credits below -->
                <p class="d-inline-block ml-2">Copyright 2021 &copy; <a class="fg-white fw-medium"></a>Prevenir Express. Todos los derechos reservados.</p>
            </div>
            <div class="col-12 col-md-6 py-2">
                <ul class="nav justify-content-end">
                    <li class="nav-item"><a target="_blank"[routerLink]="['/terminos-condiciones']" routerLinkActive="active-navegacion" class="nav-link">Terminos y condiciones</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
</footer>
