<a target="_blank" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20saber%20más%20de%20Prevenir%20Express!" class="btn-flotante">
	<h4 class="text-white mai-logo-whatsapp"></h4>
</a>

<!-- End Facebook Pixel Code -->
<div id="inicio" class=" bannerGrande pagina-inicio bg-image  ">
	<div class="container ">
		<div class="row "></div>
		<div class="container ">
			<div class="row" style="padding: 10% 0 50px 0">
				<div class="col-md-12"> <img width="50%" src="./assets/images/prevenir/uni_neg.png" /> </div>
			</div>
			<br>
			<div class="row flip-boxes">
				<div class="col-md-3 col-sm-4 col-8 flip-box">
					<div class="front">
						<div class="content text-center">
							<div class="cardy" style="width: 15rem;  background: #f7ffeb00;"> <img class="card-img-top w-80" style="width: 200px" src="../../../assets/images/prevenir/circulo1.png" alt="Card image cap">
								<div class="cardy-body">
									<h4>Previsión Funeraria</h4> </div>
							</div> <span class="click-for-more">
              </span> </div>
					</div>
					<div class="back">
						<div class="content text-center"> <b>Nuestro objetivo es desarrollar y promover la importancia de la Previsión Funeraria en Colombia.</b>
							<a target="_blank" href="http://www.prevenirexpress.com/inicio">
								<br>
								<button class=" btn botones btn-outline-info">Visitar</button>
							</a>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-4 col-6 flip-box">
					<div class="front">
						<div class="content text-center">
							<div class="cardy" style="width: 15rem;  background: #f7ffeb00;"> <img class="card-img-top w-80" style="width: 200px" src="../../../assets/images/prevenir/circulo2.png" alt="Card image cap">
								<div class="cardy-body">
									<a href="http://citas.prevenirexpress.com/">
										<h4>Descuentos Médicos</h4> </a>
								</div>
							</div>
						</div>
					</div>
					<div class="back">
						<div class="content text-center"> <b>Una plataforma que permite ofrecer sus servicios médicos con descuentos para todos los usuarios.</b>
							<a target="_blank" data-toggle="modal" data-target="#modal-visitar">
								<br>
								<button class=" btn botones btn-outline-info">Ver más</button>
							</a>
							<a target="_blank" data-toggle="modal" data-target="#modal-registro">
								<br>
								<button class=" btn botones btn-outline-info">Acceder</button>
							</a>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-4 col-6 flip-box">
					<div class="front">
						<div class="content text-center">
							<div class="cardy" style="width: 15rem;  background: #f7ffeb00;"> <img class="card-img-top w-80" style="width: 200px" src="../../../assets/images/prevenir/circulo3.png" alt="Card image cap">
								<div class="cardy-body">
									<a href="https://www.facebook.com/prevenircentrooptico/">
										<h4>Prevenir Óptica</h4> </a>
								</div>
							</div>
						</div>
					</div>
					<div class="back">
						<div class="content text-center"> <b>Somos una empresa dedicada al cuidado oftalmológico de nuestros pacientes, ofreciendo un servicio oportuno y de gran calidad.</b>
							<a target="_blank" href="https://www.facebook.com/prevenircentrooptico/">
								<br>
								<button class=" btn botones btn-outline-info">Visitar</button>
							</a>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-4 col-6 flip-box">
					<div class="front">
						<div class="content text-center">
							<div class="cardy" style="width: 15rem;  background: #f7ffeb00;"> <img class="card-img-top " style="width: 200px" src="../../../assets/images/prevenir/circulo4.png" alt="Card image cap">
								<div class="cardy-body">
									<h4>Prevenir Fundación</h4> </div>
							</div>
						</div>
					</div>
					<div class="back">
						<div class="content text-center"> <b>Próximamente</b>
							<br>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

	<div class="movil" style="background-color: #000">
		<div class=" flip-boxes">
			<div class="col-md-8">
				<br>
				<br> <img width="80%" src="./assets/images/prevenir/uni_neg.png" />
				<br>
				<br> </div>
			<div class="col-md-8 flip-box">
				<div class="front">
					<div class="content text-center">
						<div class="cardy" style="width: 15rem;  background: #f7ffeb00;"> <img class="card-img-top w-80" style="width: 200px" src="../../../assets/images/prevenir/circulo1.png" alt="Card image cap">
							<div class="cardy-body">
								<h4>Previsión Funeraria</h4> </div>
						</div> <span class="click-for-more">
              </span> </div>
				</div>
				<div class="back">
					<div class="content text-center"> <b>Nuestro objetivo es desarrollar y promover la importancia de la Previsión Funeraria en Colombia.</b>
						<a target="_blank" href="http://www.prevenirexpress.com/inicio">
							<br>
							<button class=" btn botones btn-outline-info">Visitar</button>
						</a>
					</div>
				</div>
			</div>
			<br>
			<br>
			<div class="con-md-8  flip-box">
				<div class="front">
					<div class="content text-center">
						<div class="cardy" style="width: 15rem;  background: #f7ffeb00;"> <img class="card-img-top w-80" style="width: 200px" src="../../../assets/images/prevenir/circulo2.png" alt="Card image cap">
							<div class="cardy-body">
								<a href="http://citas.prevenirexpress.com/">
									<h4>Descuentos Médicos</h4> </a>
							</div>
						</div>
					</div>
				</div>
				<div class="back">
					<div class="content text-center"> <b>Una plataforma que permite ofrecer sus servicios médicos con descuentos para todos los usuarios.</b>
						<a target="_blank" data-toggle="modal" data-target="#modal-visitar">
							<br>
							<button class=" btn botones btn-outline-info">Ver más</button>
						</a>
						<a target="_blank" data-toggle="modal" data-target="#modal-registro">
							<br>
							<button class=" btn botones btn-outline-info">Acceder</button>
						</a>
					</div>
				</div>
			</div>
			<br>
			<br>
			<div class="con-md-8 flip-box">
				<div class="front">
					<div class="content text-center">
						<div class="cardy" style="width: 15rem;  background: #f7ffeb00;"> <img class="card-img-top w-80" style="width: 200px" src="../../../assets/images/prevenir/circulo3.png" alt="Card image cap">
							<div class="cardy-body">
								<a href="https://www.facebook.com/prevenircentrooptico/">
									<h4>Prevenir Óptica</h4> </a>
							</div>
						</div>
					</div>
				</div>
				<div class="back">
					<div class="content text-center"> <b>Somos una empresa dedicada al cuidado oftalmológico de nuestros pacientes, ofreciendo un servicio oportuno y de gran calidad.</b>
						<a target="_blank" href="https://www.facebook.com/prevenircentrooptico/">
							<br>
							<button class=" btn botones btn-outline-info">Visitar</button>
						</a>
					</div>
				</div>
			</div>
			<br>
			<br>
			<div class="con-md-8 flip-box" style="margin-bottom: 100px;">
				<div class="front">
					<div class="content text-center">
						<div class="cardy" style="width: 15rem;  background: #f7ffeb00;">
              <img class="card-img-top " style="width: 200px" src="../../../assets/images/prevenir/circulo4.png" alt="Card image cap">
							<div class="cardy-body">
								<h4>Prevenir Fundación</h4> </div>
						</div>
					</div>
				</div>
				<div class="back">
					<div class="content text-center"> <b>Próximamente</b>
						<br>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade modalFotos" id="modal-registro" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg" style="background: transparent;">
			<div class="modal-content" style="background: transparent;">
				<div class="modal-body" style="background: transparent;">
					<div class="row">
						<div class="col-10"></div>
						<div class="col-2"> <i style="cursor: pointer;" data-dismiss="modal" class="text-white fa fa-times"></i> </div>
					</div>
					<div class="row">
						<div class="col-12 text-center">
							<div class="row draggable-cards" id="draggable-area">
								<div class="col-md-2 col-sm-12"></div>
								<div class="col-md-4 col-sm-12 text-center">
									<div class="card  card-hover">
										<div class="card-header bg-info">
											<h5 class="mb-0 text-white">Empresas</h5> </div>
										<div style="background-color:#000;" class="card-body">
											<h5 class="card-title text-white">Ingresa a nuestra plataforma Web</h5>
											<p class=" text-white">Recuerda que para registrar y administrar tu empresa debes usar una computadora.</p> <a href="http://citas.prevenirexpress.com/#/login" type="button" class="btn waves-effect waves-light btn-light">Ingresar</a> </div>
									</div>
								</div>
								<div class="col-md-4 col-sm-12 text-center">
									<div class="card  card-hover">
										<div class="card-header bg-info">
											<h5 class="mb-0 text-white">Usuarios</h5> </div>
										<div class="card-body" style="background-color:#000;">
											<h5 class="card-title text-white">Descarga nuestra App</h5>
											<p class=" text-white">Recuerda que para registrarte como profesional de la salud y usuario debes usar la App.</p>
											<a href="https://apps.apple.com/co/app/prevenir-descuentos-m%C3%A9dicos/id1560589182" title="Descargar desde Apple Store" class="btn btn-store aos-init aos-animate"> <img src="./assets/images/ios.png" style="height:50px;width:155px"> </a>
											<a href="https://play.google.com/store/apps/details?id=com.prevenirdescuentosmedicos.app" title="Descargar desde Play Store" class="btn btn-store aos-init aos-animate"> <img src="./assets/images/android.png" style="height:50px;width:155px"> </a>
											<a href="https://appgallery.cloud.huawei.com/ag/n/app/C104125813?locale=es_US&source=appshare&subsource=C104125813" title="Descargar desde Play Store" class="btn btn-store aos-init aos-animate"> <img src="./assets/images/gallery.png" style="height:50px;width:155px"> </a>
										</div>
									</div>
								</div>
								<div class="col-md-2 col-sm-12"></div>
							</div>
						</div>
					</div>
					<div style="display: none;">
						<button id="btn-cerrar-empleadosucu" data-dismiss="modal" style="margin-right: 8px;">Cerrar</button>
					</div>
				</div>
			</div>
		</div>
  </div>
		<!-- /.modal-content -->
		<div class="modal fade modalFotos" id="modal-visitar" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" style="background: transparent;">
        <div class="modal-content" style="background: transparent;">
          <div class="modal-body" style="background: transparent;">
            <div class="row">
              <div class="col-10"></div>
              <div class="col-2"> <i style="cursor: pointer;" data-dismiss="modal" class="text-white fa fa-times"></i> </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="row draggable-cards" id="draggable-area">
                  <div class="col-md-2 col-sm-12"></div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="card  card-hover">
                      <div class="card-header bg-info">
                        <h5 class="mb-0 text-white">Información para médicos</h5> </div>
                      <div style="background-color:#000;" class="card-body">
                        <b style="font-size:50px;" class=" text-white"><i class="fas fa-user-md"></i></b><br>
                         <a href="http://citas.prevenirexpress.com/#/info-noticias" type="button" class="btn waves-effect waves-light btn-light">Ingresar</a>
                        </div>
                    </div>
                  </div><br>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="card  card-hover">
                      <div class="card-header bg-info">
                        <h5 class="mb-0 text-white">Información para usuarios</h5> </div>
                      <div class="card-body" style="background-color:#000;">
                        <b style="font-size:50px;" class=" text-white"><i class="fas fa-user"></i></b><br>
                         <a href="http://citas.prevenirexpress.com/#/info-noticias-usuario" type="button" class="btn waves-effect waves-light btn-light">Ingresar</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-12"></div>
                </div>
              </div>
            </div>
            <div style="display: none;">
              <button id="btn-cerrar-empleadosucu" data-dismiss="modal" style="margin-right: 8px;">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>


			<!-- /.modal-content -->
			<!-- Facebook Pixel Code -->
			<script>
			! function(f, b, e, v, n, t, s) {
				if(f.fbq) return;
				n = f.fbq = function() {
					n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
				};
				if(!f._fbq) f._fbq = n;
				n.push = n;
				n.loaded = !0;
				n.version = '2.0';
				n.queue = [];
				t = b.createElement(e);
				t.async = !0;
				t.src = v;
				s = b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t, s)
			}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
			fbq('init', '139031563597380');
			fbq('track', 'PageView');
			</script>
